@font-face {
	font-family: 'fontello';
	src: url('../Fonts/fontello.eot?15965987');
	src: url('../Fonts/fontello.eot?15965987#iefix') format('embedded-opentype'),
		url('../Fonts/fontello.woff2?15965987') format('woff2'),
		url('../Fonts/fontello.woff?15965987') format('woff'),
		url('../Fonts/fontello.ttf?15965987') format('truetype'),
		url('../Fonts/fontello.svg?15965987#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'fontello';
		src: url('../font/fontello.svg?15965987#fontello') format('svg');
	}
}
*/

i[class^="icon-"], i[class*=" icon-"] {
	display: inline-block;
	line-height: 0;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
   
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	/* margin-right: .2em; */
	text-align: center;
	/* opacity: .8; */
   
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
   
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
   
	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	/* margin-left: .2em; */
   
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
   
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
   
	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-chevron-left:before {
	margin-left: -0.05em;
	margin-right: 0.05em;
}
.icon-chevron-right:before {
	margin-right: -0.05em;
	margin-left: 0.05em;
}
